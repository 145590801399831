<template>
	<v-row id="layout">
		<v-col id="layout" cols="12">
			<v-row style="max-width: 65%">
				<v-col class="d-flex align-center justify-center" cols="12" md="6">
					<img src="@/assets/musiccomp-logo.png" width="90%" />
				</v-col>
				<v-col class="d-flex align-center justify-center" cols="12" md="6">
					<div class="projekt-text">
						<span id="h1">Song Competition</span>
						<v-divider></v-divider>
						<span id="p">
							Die Grundidee ist, verschiedene Songs von YouTube und Spotify gegeneinander antreten zu lassen und mittels Voting zu entscheiden, welcher Song eine Runde weiterkommen
							darf. Das alles geschieht in einem K.O Turnier System, jede Runde gewinnt ein Song und einer verliert. Das Ganze wird solange wiederholt, bis am Ende nur noch ein
							Song übrig ist.
						</span>
						<v-btn href="https://song-competition.live/" target="_blank" rounded outlined color="var(--primary-bg-color)">Seite besuchen</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Home",
};
</script>

<style scoped>
.row#layout {
	height: inherit;
	width: inherit;

	background: linear-gradient(-12deg, var(--secondary-bg-color) 40%, var(--primary-bg-color) 40%);
}

.col#layout {
	display: flex;
	align-items: center;
	justify-content: center;
}

.projekt-text {
	background: rgba(19, 19, 19, 0.459);

	border: rgba(0, 0, 0, 0) 1px solid;
	border-radius: 10px;

	padding-top: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 0.5rem;
}

.projekt-text .v-divider {
	border-color: rgba(255, 255, 255, 0.637);
}

.projekt-text span#h1 {
	display: block;

	font-size: 38px;
	font-weight: bold;
	font-family: Poppins, sans-serif;
}

.projekt-text span#p {
	display: block;

	font-size: 17px;
	font-family: Poppins, sans-serif;

	opacity: 0.8;
}

.projekt-text .v-btn {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}
</style>
