<template>
	<v-app>
		<v-app-bar app dense dark>
			<v-spacer></v-spacer>
			<v-btn @click="toFiverr" plain>
				<span>Fiverr</span>
			</v-btn>
			<v-btn to="/" plain>
				<span>Projekte</span>
			</v-btn>

			<!--
      <v-btn to="/contact" plain>
        <span>Kontakt</span>
      </v-btn>
      -->
		</v-app-bar>

		<v-main>
			<v-container style="padding: 0px" fluid fill-height>
				<router-view />
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: "App",
	methods: {
		toFiverr() {
			window.open("https://www.fiverr.com/printnico", "_blank", "noreferrer");
		},
	},
};
</script>

<style>
:root {
	--primary-bg-color: #d4a50b;
	--secondary-bg-color: #04999e;
}
</style>
